const bengali = {
  atlas: {
    groupA: {
      fontSize: 78,
      lineHeight: 84,
    },
    groupB: {
      fontSize: 96,
      lineHeight: 104,
    },
    groupD: {
      fontSize: 140,
      lineHeight: 148,
    },
  },
  elephant: {
    groupA: {
      fontSize: 60,
      lineHeight: 64,
    },
    groupB: {
      fontSize: 78,
      lineHeight: 84,
    },
    groupD: {
      fontSize: 116,
      lineHeight: 124,
    },
  },
  imperial: {
    groupA: {
      fontSize: 50,
      lineHeight: 54,
    },
    groupB: {
      fontSize: 58,
      lineHeight: 72,
    },
    groupD: {
      fontSize: 86,
      lineHeight: 104,
    },
  },
  royal: {
    groupA: {
      fontSize: 40,
      lineHeight: 44,
    },
    groupB: {
      fontSize: 47,
      lineHeight: 60,
    },
    groupD: {
      fontSize: 68,
      lineHeight: 84,
    },
  },
  foolscap: {
    groupA: {
      fontSize: 32,
      lineHeight: 36,
    },
    groupB: {
      fontSize: 36,
      lineHeight: 44,
    },
    groupD: {
      fontSize: 51,
      lineHeight: 60,
    },
  },
  canon: {
    groupA: {
      fontSize: 27,
      lineHeight: 40,
    },
    groupB: {
      fontSize: 32,
      lineHeight: 46,
    },
    groupD: {
      fontSize: 40,
      lineHeight: 56,
    },
  },
  trafalgar: {
    groupA: {
      fontSize: 25,
      lineHeight: 32,
    },
    groupB: {
      fontSize: 29,
      lineHeight: 36,
    },
    groupD: {
      fontSize: 33,
      lineHeight: 48,
    },
  },
  paragon: {
    groupA: {
      fontSize: 24,
      lineHeight: 30,
    },
    groupB: {
      fontSize: 24,
      lineHeight: 32,
    },
    groupD: {
      fontSize: 28,
      lineHeight: 40,
    },
  },
  doublePica: {
    groupA: {
      fontSize: 22,
      lineHeight: 30,
    },
    groupB: {
      fontSize: 22,
      lineHeight: 30,
    },
    groupD: {
      fontSize: 23,
      lineHeight: 32,
    },
  },
  greatPrimer: {
    groupA: {
      fontSize: 19,
      lineHeight: 28,
    },
    groupB: {
      fontSize: 20,
      lineHeight: 30,
    },
    groupD: {
      fontSize: 21,
      lineHeight: 30,
    },
  },
  bodyCopy: {
    groupA: {
      fontSize: 14,
      lineHeight: 28,
    },
    groupB: {
      fontSize: 17,
      lineHeight: 32,
    },
    groupD: {
      fontSize: 18,
      lineHeight: 34,
    },
  },
  pica: {
    groupA: {
      fontSize: 15,
      lineHeight: 23,
    },
    groupB: {
      fontSize: 15,
      lineHeight: 23,
    },
    groupD: {
      fontSize: 17,
      lineHeight: 24,
    },
  },
  longPrimer: {
    groupA: {
      fontSize: 13,
      lineHeight: 26,
    },
    groupB: {
      fontSize: 13,
      lineHeight: 26,
    },
    groupD: {
      fontSize: 17,
      lineHeight: 30,
    },
  },
  brevier: {
    groupA: {
      fontSize: 12,
      lineHeight: 20,
    },
    groupB: {
      fontSize: 12,
      lineHeight: 20,
    },
    groupD: {
      fontSize: 16,
      lineHeight: 22,
    },
  },
  minion: {
    groupA: {
      fontSize: 10,
      lineHeight: 16,
    },
    groupB: {
      fontSize: 12,
      lineHeight: 18,
    },
    groupD: {
      fontSize: 12,
      lineHeight: 18,
    },
  },
};

export default bengali;
